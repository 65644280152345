import { template as template_b19943f037744bbfb8b1ce97fd80fd24 } from "@ember/template-compiler";
const FKLabel = template_b19943f037744bbfb8b1ce97fd80fd24(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
