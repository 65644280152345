import { template as template_9f79e2da67d14499b6bdf1a578207ef4 } from "@ember/template-compiler";
const FKText = template_9f79e2da67d14499b6bdf1a578207ef4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
