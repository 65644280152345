import { template as template_e8bf67fa3d714aa690ded3a195df2b8f } from "@ember/template-compiler";
const WelcomeHeader = template_e8bf67fa3d714aa690ded3a195df2b8f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
